footer {
  background: var(--color-primary);
  padding: 3rem 1rem;
  text-align: center;
  color: var(--color-bg);
  position: relative;
}

.footer__title {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-bg);
  margin-bottom: 1rem;
}

.permalinks {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.permalinks a {
  color: var(--color-bg);
  text-decoration: none;
  transition: color 0.3s ease;
}

.permalinks a:hover {
  color: var(--color-white);
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.footer__socials a {
  color: var(--color-bg);
  font-size: 1.2rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer__socials a:hover {
  color: var(--color-white);
  transform: scale(1.1);
}

.footer__copyright {
  font-size: 0.9rem;
  color: var(--color-bg-light);
}

.footer__copyright p {
  margin-top: 1rem;
}
