/* Global Variables */
:root {
  --color-bg: #ffffff;
  --color-bg-variant: #f4f4f4;
  --color-primary: #007acc;
  --color-primary-dark: #005f99;
  --text-secondary: #FAF9F6;
  --font-family: 'Roboto', sans-serif;
  --transition: all 0.3s ease;
}

/* Cybersecurity Section */
#cybersecurity {
  padding: 2rem 1rem;
  text-align: center;
  font-family: var(--font-family);
  background: var(--color-bg);
}

#cybersecurity h5 {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

#cybersecurity h2 {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 2rem;
}

/* Container */
.cybersecurity__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

/* Service Card */
.service {
  background: var(--color-bg-variant);
  border-radius: 8px;
  border: 1px solid transparent;
  transition: var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Service Head */
.service__head {
  background: var(--color-primary);
  padding: 1rem;
  border-radius: 8px 8px 0 0;
}

.service__head h3 {
  font-size: 1rem;
  color: var(--color-bg);
  margin: 0;
}

/* Service List */
.service__list {
  list-style: none;
  padding: 1rem;
  margin: 0;
}

.service__list li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.service__list-icon {
  color: var(--color-primary);
  font-size: 1.2rem;
}

.service__list p {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin: 0;
}

/* Link Styling */
.service__list a {
  color: var(--color-primary);
  text-decoration: underline;
  transition: var(--transition);
}

.service__list a:hover {
  color: var(--color-primary-dark);
}

/* Responsive Adjustments */
@media screen and (max-width: 600px) {
  #cybersecurity h2 {
    font-size: 1.75rem;
  }
  .cybersecurity__container {
    gap: 1rem;
  }
}
