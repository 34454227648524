nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    padding: 0.7em 1.7em;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    transition: all 0.3s ease-in-out;
}

/* When at the top of the page, only show "Get to Know Me!" */
.nav.collapsed {
    padding: 0.7em 1.5em;
}

/* When scrolled down, show full menu */
.nav.expanded {
    padding: 0.7em 2em;
}

/* Navbar buttons */
nav button {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    color: var(--color-light);
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
nav button:hover {
    background: rgba(0, 0, 0, 1);
}

/* Active section - Highlighted when user scrolls to section */
nav button.active {
    background: var(--color-bg);
    color: rgb(216, 190, 204);
    box-shadow: 0px 0px 10px rgba(216, 190, 204, 0.8);
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

/* Button text styling */
nav button span {
    font-size: 0.8rem;
    margin-left: 0.8rem;
    transition: opacity 0.3s, transform 0.3s;
}

/* Ensure "Get to Know Me!" button looks the same as others */
.nav-btn {
    display: flex;
    align-items: center;
    padding: 0.9rem;
    border-radius: 3rem; /* Keeps it uniform */
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.3s ease-in-out;
}

/* Ensure button text is always visible */
.nav.collapsed button span {
    display: inline;
}

/* Show text when expanded */
.nav.expanded button span {
    display: inline;
}
