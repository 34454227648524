/* Global Variables */
:root {
  --color-bg: #ffffff;
  --color-bg-variant: #f4f4f4;
  --color-primary: #007acc;
  --color-primary-dark: #005f99;
  --text-secondary: #555;
  --font-family: 'Roboto', sans-serif;
  --transition: all 0.3s ease;
}

/* Certifications Container */
.certifications__container {
  padding: 2rem 1rem;
  margin: 0 auto;
  max-width: 1000px;
  background: var(--color-bg);
  border-radius: 0.75rem;
  font-family: var(--font-family);
  text-align: center;
}

/* Section Header */
.certifications__container h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--color-primary);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Certifications Grid */
.certifications__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

/* Certification Card */
.certifications__item {
  background: var(--color-bg-variant);
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  text-align: center;
}

.certifications__item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Badge Container */
.certifications__badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

/* Badge Image */
.certifications__badge {
  width: 80px;
  height: auto;
  border-radius: 0.5rem;
}

/* Title and Basic Info */
.certifications__item h4 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  color: var(--color-primary);
}

.certifications__item p {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin: 0.3rem 0;
}

/* Certification Link Button */
.certifications__links {
  margin-top: 1rem;
}

.certifications__links a {
  display: inline-block;
  background: var(--color-primary);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background 0.3s;
}

.certifications__links a:hover {
  background: var(--color-primary-dark);
}

/* Responsive Adjustments */
@media screen and (max-width: 600px) {
  .certifications__item {
    padding: 1rem;
  }
  
  .certifications__item h4 {
    font-size: 1rem;
  }
  
  .certifications__item p {
    font-size: 0.85rem;
  }
  
  .certifications__links a {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }
}
