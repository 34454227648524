/* Global Variables */
:root {
  --color-bg: #ffffff;
  --color-bg-variant: #f4f4f4;
  --color-primary: #007acc;
  --color-text: #FAF9F6;
  --transition: all 0.3s ease;
  --font-family: 'Roboto', sans-serif;
}

#experience {
  padding: 2rem 1rem;
  text-align: center;
  font-family: var(--font-family);
  background: var(--color-bg);
}

#experience h5 {
  color: var(--color-text);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

#experience h2 {
  color: var(--color-primary);
  font-size: 2rem;
  margin-bottom: 2rem;
}

/* Grid Container */
.experience__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Skill Group Card */
.experience__group {
  background: var(--color-bg-variant);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: var(--transition);
}

.experience__group:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Group Heading */
.experience__group h3 {
  font-size: 1.25rem;
  color: var(--color-primary);
  margin-bottom: 1rem;
}

/* Skills List */
.experience__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.experience__list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.experience__icon {
  color: var(--color-primary);
  margin-right: 0.5rem;
  font-size: 1rem;
}

.experience__list span {
  font-size: 0.95rem;
  color: var(--color-text);
}

/* Responsive Adjustments */
@media screen and (max-width: 600px) {
  #experience h2 {
    font-size: 1.75rem;
  }
  .experience__container {
    gap: 1rem;
  }
}
