/* Global Variables */
:root {
  --color-bg: #ffffff;
  --color-bg-variant: #f4f4f4;
  --color-primary: #007acc;
  --color-primary-dark: #005f99;
  --text-secondary: #FAF9F6;
  --font-family: 'Roboto', sans-serif;
  --transition: all 0.3s ease;
}

/* Section Description */
.java-portfolio-description {
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin: 0 auto 2rem;
  max-width: 700px;
  font-family: var(--font-family);
}

/* Container Styling */
.java__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem;
  width: 100%;
  font-family: var(--font-family);
}

/* Individual Project Card */
.java__item {
  background: var(--color-bg-variant);
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  text-align: center;
}

.java__item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Title Styling */
.java__item h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

/* Description Styling */
.java__item p {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

/* Button Styling */
.java__item a.btn {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background: var(--color-primary);
  color: #fff;
  text-decoration: none;
  border-radius: 0.5rem;
  transition: background 0.3s ease;
}

.java__item a.btn:hover {
  background: var(--color-primary-dark);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .java__container {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .java__container {
    padding: 1rem;
  }
  .java__item h3 {
    font-size: 1.1rem;
  }
  .java__item p {
    font-size: 0.85rem;
  }
  .java__item a.btn {
    padding: 0.4rem 1rem;
    font-size: 0.85rem;
  }
}
